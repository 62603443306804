import Library from '../templates/Library.re'
import { graphql } from 'gatsby'

export default Library

export const query = graphql`
{

  articles: allMarkdownRemark (
    limit: 100
    filter: {
      fields: { sourceInstanceName: { eq: "articles" }},
    }
    sort: { fields: [frontmatter___date], order: DESC }
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          date
          abstract
          author
          category
        }
        fields {
          slug
        }
        html
      }
    }
  }

  videos: allMarkdownRemark (
    limit: 100
    filter: { fields: { sourceInstanceName: { eq: "videos" }}}
    sort: { fields: [frontmatter___index], order: ASC }
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          category
          image {
            publicURL
          }
          index
          link
        }
      }
    }
  }

}
`
// Strapi query
  // files: allFile(
    // filter: { sourceInstanceName: { eq: "uploads" } }
  // ) {
    // edges {
      // node {
        // name
        // publicURL
      // }
    // }
  // }

  // allStrapiArticles(
    // limit: 100
    // sort: { fields: [createdAt], order: DESC }
  // ) {
    // edges {
      // node {
        // id
        // slug
        // title
        // abstract
        // category
        // content {
          // childMarkdownRemark {
            // html
          // }
        // }
      // }
    // }
  // }
  // allStrapiVideos(
    // limit: 100
    // sort: { fields: [createdAt], order: DESC }
  // ) {
    // edges {
      // node {
        // id
        // title
        // category
        // thumbnail {
          // hash
        // }
        // link
      // }
    // }
  // }
