// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as ReactDOMRe from "reason-react/src/ReactDOMRe.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Utils$SystemaFr from "../utils/Utils";
import * as Gatsby$SystemaFr from "../utils/Gatsby";
import * as Layout$SystemaFr from "../components/Layout";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";
import * as Scripts$SystemaFr from "../utils/Scripts";

import "../assets/css/components.css"
import "../assets/css/library.css"
/* import "../assets/js/library.js" */
/* import "../assets/js/components.js" */
/* import "../assets/js/zepto.min.js" */
/* import "../assets/js/freewall.js" */
/* import "../assets/js/freewall-centering.js" */
;

var component = ReasonReact.statelessComponent("FilterButton");

function make($staropt$star, onClick, filter, selectedFilter, children) {
  var className = $staropt$star !== undefined ? $staropt$star : "";
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var data = filter !== undefined ? /* :: */[
                  /* tuple */[
                    "data-filter",
                    "." + filter
                  ],
                  /* [] */0
                ] : /* [] */0;
              var activeClass = filter !== undefined ? (
                  selectedFilter !== undefined && filter === selectedFilter ? "mdl-button--accent " : ""
                ) : (
                  selectedFilter !== undefined ? "" : "mdl-button--accent "
                );
              var tmp = {
                className: "filter-button mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect " + (activeClass + className)
              };
              if (onClick !== undefined) {
                tmp.onClick = Caml_option.valFromOption(onClick);
              }
              return Utils$SystemaFr.withDataAttributes(data, ReactDOMRe.createElementVariadic("button", Caml_option.some(tmp), children));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var FilterButton = {
  component: component,
  make: make
};

var buttonStyle = {
  height: "100%",
  padding: "0",
  width: "100%"
};

var actionsStyle = {
  padding: "0"
};

var supportIconStyle = {
  color: "rgba(255,255,255,0.8)",
  fontSize: "48px",
  height: "100%",
  minWidth: "64px",
  padding: "0",
  width: "100%",
  borderRadius: "0px"
};

var iconStyle = {
  fontSize: "48px"
};

var supportingTextStyle = {
  height: "100%"
};

function article(article$1) {
  var to_ = article$1.slug;
  return Utils$SystemaFr.withDataAttributes(/* :: */[
              /* tuple */[
                "data-category",
                "article"
              ],
              /* [] */0
            ], React.createElement("div", {
                  key: "libraryArticle-" + article$1.id,
                  className: "library-card mdl-cell mdl-cell--4-col article " + article$1.category
                }, React.createElement("div", {
                      className: "mdl-card mdl-shadow--2dp article-card"
                    }, React.createElement("div", {
                          className: "mdl-card__title mdl-card--border"
                        }, React.createElement("h2", {
                              className: "mdl-card__title-text"
                            }, ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make(to_, undefined, undefined, undefined, undefined, "mdl-button mdl-js-button mdl-button--icon mdl-js-ripple-effect", undefined, undefined, undefined, undefined, [React.createElement("div", {
                                            className: "material-icons md-dark"
                                          }, Utils$SystemaFr.text("description"))])), Utils$SystemaFr.nbsp, Utils$SystemaFr.nbsp, Utils$SystemaFr.text(article$1.title))), React.createElement("div", {
                          className: "mdl-card__supporting-text",
                          style: supportingTextStyle
                        }, ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make(to_, undefined, undefined, undefined, undefined, "library-card-text article-card-link", undefined, undefined, undefined, undefined, [Utils$SystemaFr.text(article$1.excerpt)]))), React.createElement("div", {
                          className: "mdl-card__actions mdl-card--border",
                          style: actionsStyle
                        }, ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make(to_, undefined, Caml_option.some(buttonStyle), undefined, undefined, "library-card-button mdl-button mdl-js-button mdl-js-ripple-effect", undefined, undefined, undefined, undefined, [Utils$SystemaFr.text("Lire la suite")]))))));
}

function video(video$1) {
  var href = video$1.link;
  var thumbnailSrc = video$1.thumbnail;
  var thumbnailStyle = {
    background: $$String.concat("", /* :: */[
          "url('",
          /* :: */[
            thumbnailSrc,
            /* :: */[
              "') center / cover",
              /* [] */0
            ]
          ]
        ]),
    height: "100%"
  };
  return Utils$SystemaFr.withDataAttributes(/* :: */[
              /* tuple */[
                "data-category",
                "video"
              ],
              /* [] */0
            ], React.createElement("div", {
                  key: "libraryVideo-" + video$1.id,
                  className: "library-card mdl-cell mdl-cell--4-col video " + video$1.category
                }, React.createElement("div", {
                      className: "mdl-card mdl-shadow--2dp video-card",
                      style: thumbnailStyle
                    }, React.createElement("div", {
                          className: "mdl-card__title mdl-card--border"
                        }, React.createElement("h2", {
                              className: "mdl-card__title-text"
                            }, React.createElement("a", {
                                  className: "video-link mdl-button mdl-js-button mdl-button--icon mdl-js-ripple-effect",
                                  href: href
                                }, React.createElement("div", {
                                      className: "material-icons md-dark"
                                    }, Utils$SystemaFr.text("movie"))), Utils$SystemaFr.nbsp, Utils$SystemaFr.nbsp, Utils$SystemaFr.text(video$1.title))), React.createElement("div", {
                          className: "mdl-card__supporting-text",
                          style: buttonStyle
                        }, React.createElement("a", {
                              className: "video-link mdl-button mdl-js-button mdl-button--icon mdl-js-ripple-effect video-icon",
                              style: supportIconStyle,
                              href: href
                            }, React.createElement("div", {
                                  className: "material-icons md-light",
                                  style: iconStyle
                                }, Utils$SystemaFr.text("play_circle_filled")))), React.createElement("div", {
                          className: "mdl-card__actions mdl-card--border",
                          style: actionsStyle
                        }, React.createElement("a", {
                              className: "video-link mdl-button mdl-js-button mdl-js-ripple-effect",
                              style: buttonStyle,
                              href: href
                            }, Utils$SystemaFr.text("Voir la vidéo"))))));
}

var Card = {
  buttonStyle: buttonStyle,
  actionsStyle: actionsStyle,
  supportIconStyle: supportIconStyle,
  iconStyle: iconStyle,
  supportingTextStyle: supportingTextStyle,
  article: article,
  video: video
};

var component$1 = ReasonReact.reducerComponent("Library");

function make$1(data, _children) {
  var articles = Belt_List.map(Belt_List.fromArray(data.articles.edges), (function (article) {
          return {
                  id: article.node.id,
                  slug: article.node.fields.slug,
                  title: article.node.frontmatter.title,
                  excerpt: article.node.frontmatter.abstract,
                  category: article.node.frontmatter.category,
                  content: article.node.html
                };
        }));
  var videos = Belt_List.map(Belt_List.fromArray(data.videos.edges), (function (video) {
          return {
                  id: video.node.id,
                  title: video.node.frontmatter.title,
                  category: video.node.frontmatter.category,
                  thumbnail: video.node.frontmatter.image.publicURL,
                  link: video.node.frontmatter.link
                };
        }));
  var pageUrl = ReasonReactRouter.dangerouslyGetInitialUrl(/* () */0);
  var filter = pageUrl.hash;
  var initialFilter;
  switch (filter) {
    case "" :
        initialFilter = "introduction";
        break;
    case "all" :
        initialFilter = undefined;
        break;
    default:
      initialFilter = filter;
  }
  var updateUrl = function (hashOpt) {
    return ReasonReactRouter.push(hashOpt !== undefined ? $$String.concat("", /* :: */[
                      "/",
                      /* :: */[
                        $$String.concat("/", pageUrl.path),
                        /* :: */[
                          "#",
                          /* :: */[
                            hashOpt,
                            /* [] */0
                          ]
                        ]
                      ]
                    ]) : $$String.concat("", /* :: */[
                      "/",
                      /* :: */[
                        $$String.concat("/", pageUrl.path),
                        /* :: */[
                          "#all",
                          /* [] */0
                        ]
                      ]
                    ]));
  };
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: (function (_self) {
              return Curry._1(Scripts$SystemaFr.MagnificPopup.initializeVideos, /* () */0);
            }),
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              var setFilter = function (filter, $$event) {
                $$event.preventDefault();
                updateUrl(filter);
                return Curry._1(self.send, /* SetFilter */[filter]);
              };
              var selectedFilter = self.state.filter;
              var filter = function (category) {
                if (selectedFilter !== undefined) {
                  return selectedFilter === category;
                } else {
                  return true;
                }
              };
              var partial_arg = "introduction";
              var partial_arg$1 = "ryabkovasiliev";
              var partial_arg$2 = "kadochnikov";
              var partial_arg$3 = "seminar";
              return ReasonReact.element(undefined, undefined, Layout$SystemaFr.make("Bibliothèque", "library-layout", [React.createElement("div", {
                                    className: "content"
                                  }, React.createElement("div", {
                                        className: "mdl-grid"
                                      }, React.createElement("div", {
                                            className: "mdl-cell mdl-cell--11-col"
                                          }, React.createElement("div", {
                                                className: "button-group filter-button-group"
                                              }, React.createElement("a", {
                                                    className: "mdl-button mdl-js-button mdl-button--icon mdl-js-ripple-effect",
                                                    href: ""
                                                  }, React.createElement("div", {
                                                        className: "material-icons md-dark"
                                                      }, Utils$SystemaFr.text("extension"))), Utils$SystemaFr.nbsp, Utils$SystemaFr.nbsp, Utils$SystemaFr.text("Thèmes"), Utils$SystemaFr.nbsp, Utils$SystemaFr.nbsp, ReasonReact.element(undefined, undefined, make(undefined, (function (param) {
                                                          return setFilter(undefined, param);
                                                        }), undefined, selectedFilter, [Utils$SystemaFr.text("Tout")])), Utils$SystemaFr.nbsp, ReasonReact.element(undefined, undefined, make(undefined, (function (param) {
                                                          return setFilter(partial_arg, param);
                                                        }), "introduction", selectedFilter, [Utils$SystemaFr.text("Introduction")])), Utils$SystemaFr.nbsp, ReasonReact.element(undefined, undefined, make(undefined, (function (param) {
                                                          return setFilter(partial_arg$1, param);
                                                        }), "ryabkovasiliev", selectedFilter, [Utils$SystemaFr.text("Ryabko-Vasiliev")])), Utils$SystemaFr.nbsp, ReasonReact.element(undefined, undefined, make(undefined, (function (param) {
                                                          return setFilter(partial_arg$2, param);
                                                        }), "kadochnikov", selectedFilter, [Utils$SystemaFr.text("Kadochnikov")])), Utils$SystemaFr.nbsp, ReasonReact.element(undefined, undefined, make(undefined, (function (param) {
                                                          return setFilter(partial_arg$3, param);
                                                        }), "seminar", selectedFilter, [Utils$SystemaFr.text("Articles")]))))), React.createElement("div", {
                                        className: "content mdl-grid",
                                        id: "library-grid"
                                      }, Belt_List.toArray(Belt_List.map(Belt_List.filter(articles, (function (article) {
                                                      return filter(article.category);
                                                    })), article)), Belt_List.toArray(Belt_List.map(Belt_List.filter(videos, (function (video) {
                                                      return filter(video.category);
                                                    })), video)), React.createElement("div", {
                                            className: "mdl-cell mdl-cell--4-col shuffle__sizer"
                                          })))]));
            }),
          initialState: (function (param) {
              return {
                      filter: initialFilter
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          filter: action[0]
                        }]);
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var $$default = ReasonReact.wrapReasonForJs(component$1, (function (jsProps) {
        return make$1(jsProps.data, jsProps.children);
      }));

export {
  FilterButton ,
  Card ,
  component$1 as component,
  make$1 as make,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
